import React, { useState, useEffect } from "react";
import Header from "../header";
import { useNavigate, useParams } from "react-router-dom";

const Feedback = () => {
    const navigate = useNavigate();
    const [techAssessment, setTechAssessment] = useState(0);
    const [leadership, setLeadership] = useState(0);
    const [remark, setRemark] = useState("");
    const [average, setAverage] = useState(0);

    useEffect(() => {
        const avg = (techAssessment + leadership) / 10 * 5;
        setAverage(avg);
    }, [techAssessment, leadership]);

    const handleTechAssessmentChange = (e) => {
        setTechAssessment(Number(e.target.value));
    };

    const handleLeadershipChange = (e) => {
        setLeadership(Number(e.target.value));
    };

    const handleRemarkChange = (e) => {
        setRemark(e.target.value);
    };

    const handleSubmit = () => {
        const feedbackData = {
            techAssessment,
            leadership,
            remark,
            average
        };
        console.log(feedbackData);
        navigate("/thank-you/", { state: { from: "External-interview" } });
    };

    return (
        <div>
            <Header />
            <div className="max-w-[40rem] mx-auto bg-white rounded-b-lg shadow-md mt-3">

                <div className="bg-gray-100 p-3 rounded-t-lg font-bold text-[20px]">
                    <p>Feedback</p>
                </div>
                <div className="p-6  rounded-t-lg">
                    <div className="mb-4">
                        <div className="flex justify-between items-center pb-1 ">
                            <label className="text-[#8f8f8f]">Categories</label>
                            <div className="p-1 px-2">
                                <p>Average: <span>{average}</span>/<span>5</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 border-1 border-b-2">
                        <div className="sm:flex justify-between items-center pb-4">
                            <label className="text-gray-700">Technical Assessment Template</label>
                            <div className="grid grid-cols-5 gap-2 rounded-xl border-solid border-2 border-gray-200 p-1 px-2 mt-2">
                            {[1, 2, 3, 4, 5].map((num) => (
                                <div key={num}>
                                <input
                                    type="radio"
                                    name="techAssessment"
                                    id={`tech-${num}`}
                                    value={num}
                                    checked={techAssessment === num}
                                    onChange={handleTechAssessmentChange}
                                    className="peer hidden"
                                />
                                <label
                                    htmlFor={`tech-${num}`}
                                    className="block cursor-pointer w-[40px] select-none p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:rounded-lg peer-checked:bg-[#045be84d] peer-checked:text-[#045de8]"
                                >
                                    {num}
                                </label>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <div className="sm:flex justify-between items-center pb-4">
                            <label className="text-gray-700">Leadership Skills Evaluation</label>
                            <div className="grid grid-cols-5 gap-2 rounded-xl border-solid border-2 border-gray-200 p-1 px-2 mt-2">
                            {[1, 2, 3, 4, 5].map((num) => (
                                <div key={num}>
                                <input
                                    type="radio"
                                    id={`leadership-${num}`}
                                    name="leadership"
                                    value={num}
                                    checked={leadership === num}
                                    onChange={handleLeadershipChange}
                                    className="peer hidden"
                                />
                                <label
                                    htmlFor={`leadership-${num}`}
                                    className="block cursor-pointer select-none p-2 w-[40px] text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-[#045de8] peer-checked:rounded-lg peer-checked:bg-[#045be84d]"
                                >
                                    {num}
                                </label>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="relative w-full">
                            <textarea rows="4" id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg rounded-s-gray-100 rounded-s-2 border-2 border-[#e5e7eb]" placeholder="Remark *"  value={remark}
                            onChange={handleRemarkChange} />
                        </div>
                    </div>

                    <div className="text-center">
                        <button onClick={handleSubmit} className="submit-btn" >
                            SUBMIT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedback;
